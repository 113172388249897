import React, { Component, Suspense, lazy } from 'react';
import './scss/App.scss';
import NavBar from './components/layout/NavBar';
import { Switch, Route } from 'react-router';
import NotFound404 from './containers/App/404';
import CookieBanner from './components/CookieBanner';

const Home = lazy(() => import('./containers/Home'));
const Project = lazy(() => import('./containers/Project'));
const Location = lazy(() => import('./containers/Location'));
const Gallery = lazy(() => import('./containers/Gallery'));
const Company = lazy(() => import('./containers/Company'));
const Contact = lazy(() => import('./containers/Contact'));
const Terms = lazy(() => import('./containers/Terms'));
const LogIn = lazy(() => import('./containers/App/LogIn'));
const Admin = lazy(() => import('./containers/App/Admin'));
const Pages = lazy(() => import('./containers/App/Admin'));
const Medias = lazy(() => import('./containers/App/Admin'));
const Appartments = lazy(() => import('./containers/App/Admin'));
const Settings = lazy(() => import('./containers/App/Admin'));


class App extends Component {
    render() {
        return (
          <div className="App App-container">

              <Route exact path={/^(?!.*login|.*dashboard).*$/} component={NavBar} />

              <Suspense fallback={<div id="preloader"></div>}>
                  <Switch>
                      <Route key="home" exact path='/' component={Home}/>
                      <Route key="project" exact path='/projet' component={Project}/>
                      <Route key="location" exact path='/situation' component={Location}/>
                      <Route key="gallery" exact path='/galerie' component={Gallery}/>
                      <Route key="company" exact path='/societe' component={Company}/>
                      <Route key="contact" exact path='/contact' component={Contact}/>
                      <Route key="terms" exact path='/conditions-generales' component={Terms}/>
                      <Route key="login" exact path='/login' component={LogIn}/>
                      <Route key="dashboard" exact path='/dashboard' component={Admin}/>
                      <Route key="pages" exact path='/dashboard/pages' component={Pages}/>
                      <Route key="medias" exact path='/dashboard/medias' component={Medias}/>
                      <Route key="appartments" exact path='/dashboard/appartments' component={Appartments}/>
                      <Route key="settings" exact path='/dashboard/settings' component={Settings}/>
                      <Route path="*" component={NotFound404} status={404}/>
                  </Switch>
                  <CookieBanner />
              </Suspense>
          </div>
    );
  }
}

export default App;