import React, {Component} from 'react';
import logo from "../../kinvest_logo.svg";
import { NavLink } from 'react-router-dom';


const position = {
    relative: {
        position: 'relative'
    },
    fixed: {
        position: 'fixed'
    }
}


class NavBar extends Component {
    state = {
        isOpen: false,
        isFixed: false
    }

    myScript = () => {

        if (window.pageYOffset >= 50) {
            this.setState({isFixed: true})
        } else {
            this.setState({isFixed: false})
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.myScript);
    }

    close = () => {
        this.setState({isOpen: false})
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        const { isOpen, isFixed } = this.state
        return (
            <div style={isFixed ? position.fixed : position.relative } className="navbar_container">
                <div className="logo_container">
                    <NavLink className="menu_item" to="/">
                        <img src={logo} alt="Kinvest logo"/>
                    </NavLink>
                </div>

                <div className="menu_container">
                    <div className="desktop_container">
                        <NavLink exact className="menu_item" to="/">Accueil</NavLink>
                        <NavLink exact className="menu_item" to="/projet">Projet</NavLink>
                        <NavLink exact className="menu_item" to="/situation">Situation</NavLink>
                        <NavLink exact className="menu_item" to="/galerie">Galerie</NavLink>
                        <NavLink exact className="menu_item" to="/societe">Société</NavLink>
                        <NavLink exact className="menu_item" to="/contact">Contact</NavLink>
                    </div>

                    <button className="btn_burger" onClick={this.toggle}>
                        <i className="fas fa-bars"></i>
                    </button>
                    { isOpen &&
                        <div className="burger_container">
                            <ul>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/">
                                        <i className="fas fa-home"></i>Accueil
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/projet">
                                        <i className="fas fa-project-diagram"></i>Projet
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/situation">
                                        <i className="fas fa-location-arrow"></i>Situation
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/galerie">
                                        <i className="fas fa-image"></i>Galerie
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/societe">
                                        <i className="fas fa-briefcase"></i>Société
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} className="menu_item" to="/contact">
                                        <i className="fas fa-phone"></i>Contact
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default NavBar;